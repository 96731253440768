import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'

import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from './UseBulkShipmentTracker'

const Billing_wallet = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let navigate = useNavigate()
    const[state,setState] = React.useState({wallet_balance:"" })
    const[balancestate, setbalanceState ]= React.useState({add_balance:[], total_balance:[] , total_credit:0 , total_debit:0})
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
    const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();
    const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
   React.useEffect(()=>{
    get_wallet_balance(0,true)
    dimension_detail()
   },[otherStates.rowsPerPage])

   React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    console.log("urlSearchParams == " , urlSearchParams)
    // Get and set the customer_id from the URL
 
    const urlid = urlSearchParams.get('transaction_id');
    console.log("urlid == " , urlid)

    if(urlid!=undefined && urlid!=""){
     
        let dataToSend = {transaction_id:urlid};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/user/check_transaction_for_cashfree';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
                })
                Toast.fire({
                  background:"#0d6efd",
                  type: 'success',
                  title: res.data.message,
                  color:"white"
                });
                const newUrl = window.location.pathname;
                window.history.replaceState({}, document.title, newUrl);
        }
       })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
      
    }
      },[])

    const get_wallet_balance=(index=0,onLoad)=>{
     let dataToSend = {customer_id : userData.customer_id ,  limit:otherStates.rowsPerPage,indexValue:index};
    
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/get_wallet_balance';
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            if(res.data.status==true){
              if( onLoad) {
                setOtherState({...otherStates,total_count:res.data.count, page:index})         
  
    }
        setbalanceState({add_balance:res.data.added_wallet_transactions, total_balance:res.data.find_customer_wallet , total_credit:res.data.credit_value , total_debit:res.data.debit_value})
     }
    else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'Unsuccess',
            title: "Something Went Wrong !",
            color:"white"
          });
    } })
        
          .catch((error) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'Unsuccess',
                title: "Something Went Wrong !",
                color:"white"
              });
              // console.log(error);
          });    
    
    }
    const handlePageChange = (event,newPage) =>{
      setOtherState({...otherStates,page:newPage})
      // console.log("newPage ", newPage);
      // getCountryList(newPage);
      get_wallet_balance(newPage,true)
  }
  const handleChangeRowsPerPage = (event , newPage) => {
      // console.log("event ", event.target.value);
      setOtherState({ ...otherStates, rowsPerPage: event.target.value })
       
  }
    // const handleChange = (e) => {
    //     // console.log("e ", e.target.name);
    //     // console.log("e checked", e.target.value);
    //    setState({wallet_balance:e.target.value})
    //   }

    //   const addbalance=()=>{
    //      if(state.wallet_balance!=""){
    //     navigate({pathname:`/checkout`},{state:state.wallet_balance})
    //      }
    //      else{
    //         const Toast = Swal.mixin({
    //             toast: true,
    //             position: 'bottom-end',
    //             showConfirmButton: false,
    //             timer: 3000
    //           })
    //           Toast.fire({
    //             background:"rgb(231, 91, 75)",
    //             type: 'Unsuccess',
    //             title: "Please Fill the Amount !",
    //             color:"white"
    //           });

    //      }
    // }
    const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }
  return (
    <div>
          {/* <?php include 'meta.php';?> */}
    
    {/* <?php include 'left_menu.php';?> */}
<Left_menu value={9}/>
    <section class="home ">
        {/* <?php include 'header.php';?>  */}
<Header/>
        

        {/* <?php include 'billing_nav.php';?>    */}
<Billing_nav/>
        <section class="mb-3">
          <div class="container">
            <div class="card border-0">
              <div class="card-body">
                <div class="row states stboxbil py-3">
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded  ">
                        {balancestate.total_balance.map((sub)=>( <h5>&#8377;{parseFloat(sub.wallet_balance).toFixed(2)}</h5>))}
                            <small>Total Balance</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;{parseFloat(balancestate.total_credit).toFixed(2)}</h5>
                            <small>Total Credit</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377; -{parseFloat(balancestate.total_debit).toFixed(2)}</h5>
                            <small>Total Debit</small>
                        </div>
                    </div>
                </div>
              </div>
            </div>     
          </div>
        </section>

        <section class="mb-3">
          <div class="container">
            <div class="row">
                <div class="col-12 col-md-4">
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a class="nav-link active" href="/customer/billing_wallet">Wallet History</a>
                    </li>
                    {/* <li class="nav-item">
                      <a class="nav-link" href="/customer/billing_wallet_recharge">Recharge History</a>
                    </li> */}
                  </ul>
                </div>
                {/* <div class="col-12 col-md-4">
                  <div class="input-group mb-3 input-group-sm">
                      <input type="date" class="form-control" placeholder="Search by Order ID"/>
                      <button class="btn btn-primary" type="submit">Update</button>
                  </div>
                </div> */}
            </div>
            <div class=" table-responsive">
                <table class="table table-bordered table_box billing_table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>SR Transaction ID</th>
                        <th>Amount</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                    {balancestate.add_balance.map((sub)=>(
                      <tr>
                        <td><Moment format="MMM DD YYYY">
        {new Date(sub.created*1000)}
</Moment>  <Moment format="hh:mm:ss a ">
        {new Date(sub.created*1000)}
</Moment></td>
                        <td><a href="">{sub.transaction_id}</a></td>
                        <td>{sub.amount}</td>
                        <td>{sub.payment_type==1?`Point credited into the wallet (${sub.payment_method})`:`Point debited from the wallet (${sub.payment_method})`}</td>
                      </tr>
                    ))}
                      
                   
                   
                    </tbody>
                </table>  
            </div>
          </div>
        </section>
        <TablePagination
                component="div"
                rowsPerPageOptions={[20,25,30,35,40,45,50]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />
    </section>
    <Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />


    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Billing_wallet