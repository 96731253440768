import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'

import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from './UseBulkShipmentTracker'
const Billing_shipping_charges = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const [state,setState] = React.useState([])
    const[loading, setloading] = React.useState(true)
  const[balancestate,setbalanceState]= React.useState({total_balance:[]})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
  const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();

  const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
    React.useEffect(()=>{
        list(0,true)
       
    },[otherStates.rowsPerPage])

    const list = (index=0,onLoad)=>{

        let dataToSend = {customer_id : userData.customer_id , limit:otherStates.rowsPerPage,indexValue:index};
    
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/user/shipping_charges_transaction';
        axios.post(url, dataToSend, { headers: config.headers })
            .then((res) => {
              // console.log("responseJson => ", res);
              if(res.data.status==true){
                if( onLoad) {
                  setOtherState({...otherStates,total_count:res.data.count ,  page:index})         
    
      }
       setState(res.data.output)
       setloading(false)
       }
      else{
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
            })
            Toast.fire({
              background:"rgb(231, 91, 75)",
              type: 'Unsuccess',
              title: "Something Went Wrong !",
              color:"white"
            });
      } })
          
            .catch((error) => {
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
                })
                Toast.fire({
                  background:"rgb(231, 91, 75)",
                  type: 'Unsuccess',
                  title: "Something Went Wrong !",
                  color:"white"
                });
                // console.log(error);
            });    
    }
    
    const handlePageChange = (event,newPage) =>{
      setOtherState({...otherStates,page:newPage})
      // console.log("newPage ", newPage);
      // getCountryList(newPage);
      list(newPage,true)
  }
  const handleChangeRowsPerPage = (event , newPage) => {
      // console.log("event ", event.target.value);
      setOtherState({ ...otherStates, rowsPerPage: event.target.value })
       
  }
     
      
      
    //   useEffect(() => {
    
    //     if(userDetail!= null) {
    //       let uDetail = JSON.parse(userDetail)
    //       setUserState({full_name:uDetail.full_name})
    //       config.UserId = uDetail.customer_id
    //     }
    //   }, [])
      

  return (
    <div>
          {/* <?php include 'meta.php';?> */}
    
    {/* <?php include 'left_menu.php';?> */}
<Left_menu value={7}/>
    <section class="home ">
        {/* <?php include 'header.php';?>  */}
<Header/>
<Billing_nav/>
        {/* <?php include 'billing_nav.php';?>     */}
        
        {/* <section class="mb-3">
          <div class="container">
            <div class="card border-0">
              <div class="card-body">
                <div class="row states stboxbil py-3">
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded  ">
                            <h5>&#8377;0.00</h5>
                            <small>Total Freight Charges</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Billed Freight Charges</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Unbilled Freight Charges</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Total On-Hold Amount</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Invoice Due Amount</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Engage Charges</small>
                        </div>
                    </div>
                </div>
              </div>
            </div>     
          </div>
        </section> */}

        <section class="mb-3">
          <div class="container">
            {/* <div>
                <div class="input-group mb-3 input-group-sm">
                    <input type="text" class="form-control" placeholder="Search by Order ID"/>
                    <button class="btn btn-primary" type="submit">Search</button>
                  </div>
            </div> */}
            <div class=" table-responsive">
            {loading? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"50px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
    </div>:
                <table class="table table-bordered table_box billing_table">
                    <thead>
                      <tr>
                        <th>Shipment ID</th>
                        <th>AWB Number</th>
                        <th>Courier</th>
                        <th>Shipment Status</th>
                        <th>AWB Assigned Date</th>
                        <th>Applied Weight Charges (₹)</th>
                        {/* <th>Excess Weight Charges (₹)</th> */}
                        {/* <th>On Hold Amount (₹)</th> */}
                        {/* <th>Total Freight Charges (₹)</th> */}
                        <th>Entered Weight & Dimensions</th>
                        {/* <th>Charged Weight & Dimensions</th> */}
                        {/* <th>View Transactions Details</th> */}
                      </tr>
                    </thead>
                 
                    <tbody>
                        {state.length>0?
                        state.map((sub)=>(
                     <tr>
                        <td>{sub.shipment_id}</td>
                        <td>{sub.carrier_tracking_number}</td>
                        <td>{sub.service_provider}</td>
                        <td>{sub.status==0?<span class="badge rounded-pill bg-warning fw-400">Pending</span>:sub.status==1?<span class="badge rounded-pill bg-success fw-400">Pickup Awaited</span>:sub.status==2?<span class="badge rounded-pill bg-warning fw-400">Picked Up</span>:sub.status==3?<span class="badge rounded-pill bg-danger fw-400">In Transit</span>:sub.status==4?<span class="badge rounded-pill bg-primary fw-400">Out For Delivery</span>:sub.status==5?<span class="badge rounded-pill bg-success fw-400">Delivered</span>:sub.status==6?<span class="badge rounded-pill bg-dark fw-400">Cancelled</span>:sub.status==7?<span class="badge rounded-pill bg-secondary fw-400">Failed</span>:sub.status==8?<span class="badge rounded-pill bg-warning fw-400">NDR</span>:sub.status==9?<span class="badge rounded-pill bg-danger fw-400">Pickup Scheduled</span>:sub.status==10?<span class="badge rounded-pill bg-danger fw-400">Not Picked</span>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}</td>
                        <td><Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> - <Moment format="hh:mm:ss a">
                        {new Date(sub.created*1000)}
            </Moment></td>
                        <td>{sub.total_amount} {sub.status==6? " (Refunded)":" (Debited)"}</td>
                        <td>{sub.total_weight} KG <small>{sub.height} x {sub.width} x {sub.length} (cm)</small></td>
                       

                     </tr>
                     )) : 
                     <tr>
                     <td colspan="12" class="text-center">
                         Sorry! There is no data available at the moment.
                     </td>
                   </tr>}
                    </tbody>

                </table>
}  
            </div>
          </div>
        </section>
        <TablePagination
                component="div"
                rowsPerPageOptions={[20,25,30,35,40,45,50]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />
       
    </section>
    <Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />

    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Billing_shipping_charges
